<template>
    <Layout ref="main">
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-12">
          
          <div class="card border rounded-3 shadow-none" id="card_section">
            <div class="card-title p-3 rounded-top mb-0 custom-page-header">
              <div class="d-md-flex align-items-center">
                <div class="me-2 d-inline-block"><i class="bi bi-person font-size-24"></i></div>
                <div class="mb-2 mb-md-0 d-inline-block">
                    <h4 class="fw-medium font-size-20 mb-0"> {{ title }}</h4>
                    <small class="d-block font-size-13 fw-normal">Create a sub-account for additional access</small>
                </div>
                <div class="ms-auto" v-if="create_modify_permission">
                  <button class="btn btn-primary fw-medium me-2" @click="createUser(currentAccount)">
                    <i class="uil uil-plus font-size-16 me-1"></i> Create New User
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div>
                <div class="row">
                <div class="col-12">
                  <div class="table-responsive text-nowrap font-size-14 position-relative">
                    <table class="table mt-0 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                      <thead class="text-uppercase font-size-13">
                        <tr>
                          <th>Username<br>Name</th>
                          <th>Position<br>Email</th>             
                          <th>Created Date</th>
                          <th>Date Modified</th>
                          <th class="text-center">Status</th>
                          <th class="text-center">Last Login</th>
                          <th class="text-center">Login Block</th>
                          <th class="text-center">Permissions</th>  
                          <th class="text-center" v-if="create_modify_permission">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="!returnData.length && !loading">
                          <td :colspan="create_modify_permission ? 7 : 6" height="300" class="text-center text-muted">
                            <i class="uil uil-sitemap me-1"></i> No Data Available
                          </td>
                        </tr>
                        <tr v-if="loading">
                          <td :colspan="create_modify_permission ? 7 : 6" class="text-center text-muted" height="400">
                            <div
                              class="spinner-border text-secondary my-2"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                            <br />
                            <div>Loading...</div>
                          </td>
                        </tr>
                        
                        <tr v-for="(value, index) in returnData" :key="index" v-show="!loading">
                          <td class="text-muted">

                            <span class="text-primary fw-medium">{{value.username}}</span><br>
                            {{value.name}}
                          </td>
                          <td class="text-muted">
                            {{value.position}}<br>
                            {{value.email}}
                          </td>
                          <td class="text-muted">
                            {{value.date_created}}
                          </td>
                          <td class="text-muted">
                            {{value.date_modified|| '-'}}
                          </td>
                          <td class="text-center text-muted">
                            <div>
                              <span v-if="value.status=='unavailable'" class="text-danger badge border border-danger fw-normal font-size-13 text-capitalize text-uppercase">{{value.status}}</span>
                              <span v-else-if="value.status=='available'" class="text-success badge border border-success fw-normal font-size-13 text-capitalize text-uppercase"> {{value.status}}</span>
                            </div>
                          </td>
                          <td class="text-center text-muted">
                           {{value.last_login || '-'}}
                         </td>
                          <td class="text-center">
                            
                            <div>
                              <span v-if="value.login_block=='1'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">Blocked</span>
                              <span v-else-if="value.login_block=='0'" class="text-muted font-size-14 me-1 text-capitalize text-uppercase">Unblock</span>
                            </div>
                            
                          </td>
                          <td class="text-center">
                            <a href="javascript:void(0)" v-b-tooltip.hover title="" class="text-primary" @click="showModules(value)">
                              <i class="bi bi-eye"></i> View Permissions
                            </a>
                          </td>
                          <td class="text-center" v-if="create_modify_permission">
                            <div v-if="accessUsername!==value.username">
                              <button class="btn btn-lg custom-button rounded-pill p-2 lh-1 me-2" @click="editUser(value)" :disabled="disabled">
                              <i class="bi bi-pencil-square"></i></button>


                              <button class="btn btn-lg custom-button rounded-pill p-2 lh-1" @click="removeUser(value)" :disabled="disabled">
                              <i class="bi bi-trash"></i></button>
                            </div>
                            <div v-else class="text-muted font-size-13"> Current Account</div>
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                </div>
                <div class="row mt-3" v-if="returnData.length && !loading">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          hide-ellipsis
                          @change="handlePageChange"
                          v-model="currentPage"
                          :per-page="perPage"
                          :total-rows="totalData"
                        >
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Lottie :path="'/animate/loading_blue.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
      <Common ref="commonFunc"/>
      <users ref="modalFunc" :data="{'accessUsername':accessUsername, 'assessToken':accessToken, 'updateData':updateData}"  @callParentFunction="handlePageChange(currentPage)" />
    </Layout>
  </template>
  
  <script>
  
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import Lottie from "@/components/lottieView";
  import Common from "@/components/common";
  import axios from "axios";
  import Swal from "sweetalert2";
  import users from "@/views/pages/app/modal/createEditUser";
  /**
   * Page
   */
  const PageTitle = "User Management"
  
  export default {
    components: { 
      Layout, 
      PageHeader, 
      Lottie,
      Common,
      users
    },
    page() {
      return {
        title: PageTitle,
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      }
    },
    data() {
      return {
        accessToken:'',
        accessEmail:'',
        accessPhone:'',
        accessUsername:'',
        account_type:'',
        showLottie:false,
        title: '',
        items: [
          {
            text: appConfig.pageTitle,
            to: "/",
          },
          {
            text: '',
            active: true,
          },
        ],
        formData:{
          apiID: "YOUR_API_ID",
          apiHash:"YOUR_API_HASH",
        },
        submitted:false,
        loading:false,
        buttonLoading:[false,false,false],
        currentPage: 1,
        currencySymbol:"",
        seletedMethod: "",
        selectedStatus:"",
        searchTransactionId: "",
        searchMerchantItemId:"",
        filterType:"created_date",
        searchAmount:"",
        perPage: 20,
        totalData: 0,
        returnData: [],
        returnTotalAmount: 0,
        returnTotalTransaction:0,
        returnSuccessRate: 0,
        export_data: [],
        search_value: "",
        disableExport: false,
        loadingButton: false,
        date_range: null,
        disable_date: false,
        disabled:false,
        shortcuts:[],
        currentAccount:{},
        currentMerchantInfo:{},
        currentResellerInfo:{},
        updateData:{},
        create_modify_permission:false,
        userListAPIUrl: "",
        deleteUserAPIUrl: "",
      };
    },
    middleware: "authentication",
    async mounted(){
     
      this.title = PageTitle
      this.items[1].text = PageTitle
      // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      // this.$refs.main.changeVerticalTopBar("bill",true)
      // this.$refs.main.setShowFooterCert(false)
      // this.$refs.main.setPageTitle('title')
      await this.reload()
      this.accessToken = this.$refs.commonFunc.getToken()
      this.accessUsername = this.$refs.commonFunc.getUsername()
      this.date_range = this.$refs.commonFunc.last30Days()
      this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    
      this.tabsChanging(0)
    }, 
    created(){
    },
    methods:{
      tabsChanging: function(e){
       
        console.log(this.accessUsername);
        console.log(e);
        if (e==0){
          if (this.account_type == 'admin'){
            this.currentAccount = {
            "account_db_code": "",
            "account_type": "admin",
          }
            this.userListAPIUrl = 'controller/user/getAllUserList'
            this.deleteUserAPIUrl = 'controller/user/deleteUser'
          }
          this.handlePageChange(1);
        }
      },
      
      createUser(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc.showModal(),100)
      },
      editUser(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc.showEditModal(),100)
      },
      showModules(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc.showModules(),100)
      },
      removeUser(value) {
          Swal.fire({
            icon: 'info',
            title: `Confirm User Deletion?`,
            html: `Are you sure you want to delete this ${value.name}? <br> This action is irreversible, so make sure you want to proceed.`,
            confirmButtonColor: '#FA6541',
            iconColor: '#60b725',
            showCancelButton: true,
            confirmButtonText: "Yes"
          }).then((result) => {
            if (result.isConfirmed) {
              this.$Progress.start();
              this.loading = true
              this.disabled = true
              var bodyFormData = new FormData();
              bodyFormData.append("accessToken", this.accessToken);
              bodyFormData.append("accessUsername", this.accessUsername);
              bodyFormData.append("accountDbCode", value.account_db_code);
              axios({
                method: "post",
                url: appConfig.APIHost + this.deleteUserAPIUrl,
                data: bodyFormData,
                headers: {
                  "Content-Type": "multipart/form-data"
                },
              }).then((response) => {
                this.returnData = []
                var resData = response.data;
                if (resData.status == 200) {
                  console.log(resData)
                  this.handlePageChange(1)
                  Swal.fire({
                    icon: 'success',
                    title: `${value.name} Deleted Successfully`,
                    html: 'The selected user has been successfully deleted.',
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done')
                  })
                } else if (resData.status == 440) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  this.$refs.commonFunc.clearData()
                  this.$router.push({
                    path: "/login",
                  });
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                }
                this.loading = false
                this.disabled = false
                this.$Progress.finish();
              }).catch((error) => {
                this.disabled = false;
                this.loading = false
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
              });
            }
          })
          
          
        },
      
      detectObject(value){
        if (typeof value === 'object'){
          return true
        }else{
          return false
        }
      },
        convertCurrencyFormat(value,show00) {
          if (show00==true) {
            return this.$refs.commonFunc.convertCurrencyFormat(value)
          }else{
             // Assuming this.transactionLimit is defined in your component's data
             return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
          }
        },
        inputNumberOnly(event, parentModel) {
          const numericValue = event.target.value.replace(/[^0-9.]/g, '');
          this[parentModel] = numericValue;
        },
        countryChanged(){
          this.handlePageChange(1)
        },
        returnBankList(){
          if (this.selectedCountry.id !==-1){
            return this.banksList.filter(bank => bank.countryId === this.selectedCountry.id);
          }else{
            return this.banksList
          }
        },
        notBeforeToday(date) {
          return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        DateChanged() {
          console.log("datechange" + this.date_range);
          this.handlePageChange(1);
          this.currentPage = 1;
          this.disable_date = true;
        },
        handlePageChange(current_page) {
          this.pageNum = current_page;
          this.currentPage = current_page;
          this.getData(this.pageNum, this.search_value);
          //this.exportData(1)
        },
  
        search() {
          this.handlePageChange(1);
          this.currentPage = 1;
        },
        clear() {
          this.seletedMethod= ""
          this.selectedStatus= ""
          this.searchTransactionId= ""
          this.searchMerchantItemId= ""
          this.filterType="created_date"
          this.searchAmount=""
          this.date_range = this.$refs.commonFunc.last30Days()
  
          this.search_value = "";
          this.handlePageChange(1);
          this.currentPage = 1;
        },
  
        exportToExcel(Data,name,type) {
          var title = name.replace(/ /g, "_");
          var ws = window.XLSX.utils.json_to_sheet(Data);
          let objectMaxLength = []; 
              for (let i = 0; i < Data.length; i++) {
              let value = Object.values(Data[i]);
              for (let j = 0; j < value.length; j++) {
                  if (typeof value[j] == "number") {
                  objectMaxLength[j] = 5;
                  } else {
                  objectMaxLength[j] =
                      objectMaxLength[j] >= value[j].length
                      ? parseInt(objectMaxLength[j])
                      : parseInt(value[j].length) + 5;
                  }
              }
          }
          var wscols =[]
          objectMaxLength.forEach((value, index) => {
            console.log(value)
            wscols.push({width: objectMaxLength[index]})
          }),
          ws['!cols'] = wscols;
          
          var wb = window.XLSX.utils.book_new();
          window.XLSX.utils.book_append_sheet(wb, ws, name)
          window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
      },
      async getData(pages, keyword){
        console.log("getData")
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        this.disable_date = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        console.log(keyword)
        axios({
            method: "post",
            url:  appConfig.APIHost + this.userListAPIUrl,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            if (resData.status == 200) {
              this.totalData = resData.total;
              var responseData = resData.data;
              this.returnData = responseData;
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false;
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
      },
      async exportData(){
          this.$Progress.start();
          this.disableExport = true;
          this.loadingButton = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("phone",  this.accessPhone);
          // bodyFormData.append("page", pages);
          // bodyFormData.append("limit", this.perPage);
          axios({
              method: "get",
              url: `https://dummyjson.com/users/search?q=&limit=100&skip=1`,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              this.export_data = [];
              var resData = response.data;
              this.totalData = resData.total
              var users = resData.users
             
  
              for (var i in users) {
                   // Combine date and time
                   const depositDateTime = `29 Jun 2023, 12:30:00`;
                  // Generate a random deposit amount
                  const randomDeposit = Math.floor(Math.random() * 10000) + 1000; // Random amount between 1000 and 11000
  
                  // Calculate merchant fee and nett amount
                  const merchantFeePercentage = 2.5 / 100; // 2.5% merchant fee
                  const merchantFee = Math.floor(randomDeposit * merchantFeePercentage);
                  const nettAmount = randomDeposit - merchantFee;
                  const randomStatus = Math.random() < 0.8 ? "success" : "failed";
                  var randomMode = Math.random() < 0.8 ? "UPI" : "IMPS";
                  var transaction_id = ""
                  if (randomMode=="UPI"){
                    transaction_id = "320020673657"
                  }else{
                    transaction_id = "IMPS/123443211240"
                  }
  
                  const listData = {
                    "id":"ids",
                    "deposit_date": depositDateTime,
                    "customer_id": `${users[i].id}`,
                    "customer_name":`${users[i].firstName} ${users[i].lastName}`,
                    "deposit_amount": randomDeposit.toString(),
                    "deposit_mode":randomMode.toString(),
                    "merchant_fee": merchantFee.toString(),
                    "nett_amount": nettAmount.toString(),
                    "transaction_id":transaction_id,
                    "status": randomStatus,
                  };
                  this.export_data.splice(i, 0, listData);
                }
                var self = this
                setTimeout(function() {  
                  self.disableExport = false;
                  self.loadingButton = false;
                  self.exportToExcel(self.export_data,'Summary','xlsx')
                }, 1200);
               
              // if (resData.status == 200) {
              //   console.log(resData)
              //   this.totalData = 10
              // } 
              // else if (resData.status == 440){
              //   Swal.fire(
              //       {
              //       icon: 'error',
              //       title: 'Oops...',
              //       html: `${resData.message}.`,
              //       confirmButtonColor: '#222',
              //       confirmButtonText: this.$t('siteLang.Done'),
              //     })
              //     localStorage.clear();
              //     this.$router.push({
              //         path: "/login",
              //   });
              // }
              // else {
              //     Swal.fire(
              //       {
              //       icon: 'error',
              //       title: 'Oops...',
              //       html: `${resData.message}.`,
              //       confirmButtonColor: '#222',
              //       confirmButtonText: this.$t('siteLang.Done'),
              //     })
              // }
              this.loading = false;
              this.$Progress.finish();
          })
          .catch((error)=> {
              this.disableExport = false;
              this.loadingButton = false;
              this.loading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      },
      async reload() {
        try {
          await this.$nextTick(); // Wait for the component to be fully mounted
  
          const commonFunc = this.$refs.commonFunc;
          if (commonFunc && commonFunc.getFullData) {
            const data = await commonFunc.getFullData();
            this.account_type = data.account_type;
            const permission = data.modules.filter(e => e.name==='user_management')
            if (permission) {
              this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
              console.log(this.create_modify_permission)
            } else {
              console.log('Module with name "user_management" not found.');
            }
          } else {
            // Handle the case when commonFunc or getFullData is undefined
            // Perform appropriate actions or show a fallback message to the user
          }
        } catch (error) {
          // Handle the error silently without propagating it further
        }
      },
  
    }
  };
  </script>